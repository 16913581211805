import React, {useEffect, useRef, useState, useLayoutEffect} from 'react'
import { useField } from 'formik'
import { FormGroup, Input } from 'reactstrap'

const useKeyPress = (targetKey) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false)

  // If pressed key is our target key then set to true
  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true)
    }
  }

  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false)
    }
  }

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return keyPressed
}


const displayAmount = (amount) => amount

const useRunAfterUpdate = () => {
  const afterPaintRef = useRef(null)

  useLayoutEffect(() => {
    if (afterPaintRef.current) {
      afterPaintRef.current()
      afterPaintRef.current = null
    }
  })

  const runAfterUpdate = (fn) => (afterPaintRef.current = fn)


  return runAfterUpdate
}

const separator = "."


const AmountField = (props) => {
  const [field] = useField(props)

  const backspaceKey = useKeyPress('Backspace')
  const runAfterUpdate = useRunAfterUpdate()

  const handleChange = (event) => {
    const input = event.target
    let value = input.value.toString()
    let cursor = input.selectionStart

    if (value === "") {
      value = "0" + separator + "00"
      cursor += 1
    }

    //si dernier caractere "," ou pas de "," alors on met des decimals à 0
    if (value.slice(-1) === separator || (value.indexOf(separator) === -1 && !backspaceKey)) {
      if (separator === ",") {
        value = parseInt(value).toFixed(2).replace(".", ",")
      } 

      if (separator === ".") {
        value = parseInt(value).toFixed(2)
      }
    }

    //Si il y a un separator
    if (value.indexOf(separator) > -1) {
      const decimal = value.split(separator)

      //plussieur separator on supprime celui qui vien de s'ajouter
      if (decimal[1].length > 2) {
        value = value.slice(0, -1)
      }

      //un seul decimal on met un 0 à la fin
      if (decimal[1].length === 1) {
        value = value.concat("0")
      }


      //pas de chiffre avant la virgule on met un 0, le cursor est déplacé à droite
      if (decimal[0].length === 0) {
        value = "0".concat(value)
        cursor += 1
      }
    }

    //on supprier le 0 si c'est le premier caractère le cursor est déplacé à gauche
    if ((separator === "," && /^0\d+(?:[\,]00)$/.test(value)) || (separator === "." && /^0\d+(?:[\.]00)$/.test(value))) {
      value = value.substr(1)
      cursor -= 1
    }

    const regex = /^(\d+(?:[\.\,]\d{2}))$/

    //la valeur est un chiffre à 2 decimal on met à jour
    if ((separator === "," && /^(\d+(?:[\,]\d{2}))$/.test(value.toString())) || (separator === "." && /^(\d+(?:[\.]\d{2}))$/.test(value.toString()))) {   
      field.onChange(props.name)(Number((value.replace(",",".")) * 100).toFixed(0))
    } else {
       //sinon on laisse l'ancienne
      field.onChange(props.name)(field.value)
    }

    //on met à jour le cursor
    runAfterUpdate(() => {
      input.selectionStart = cursor
      input.selectionEnd = cursor
    })
  }

  const renderValue = () => {
    let value = field.value === '' ? '' : (field.value / 100).toFixed(2)
    return value
  }

  return (
    <FormGroup>
      <Input bsSize="sm" className="text-end" {...field} value={renderValue()} onChange={handleChange} {...props} />
    </FormGroup>
  )
}


export default AmountField