import React, {useEffect, useRef } from 'react'
import { Formik, useField, FieldArray, useFormikContext } from 'formik'

import { Form, FormGroup, Label, Input, Button, Row, Col, Table, FormFeedback, FormText }  from 'reactstrap'

import AmountField from './AmountField'

const TextField = ({ label, required, helpText, ...props }) => {
  const validate = value => {
    let errorMessage

    if (!value && required) {
      errorMessage = 'Champ obligatoire'
    }

    return errorMessage
  }

  const [field, meta, helpers] = useField({validate, ...props})

  return (
    <FormGroup>
      {label && <Label>{label} {required && '*'}</Label>}
      <Input bsSize="sm" {...field} {...props} invalid={meta.touched && meta.error} />
      {meta.touched && meta.error ? (
         <FormFeedback>{meta.error}</FormFeedback>
       ) : null}
      {helpText && <FormText>{helpText}</FormText>}
    </FormGroup>
  )
}

// <Button type="button" onClick={() => arrayHelpers.push({id: '', amount: ''})}>
//     Ajouter une facture
//  </Button>

const TotalAmount = (props) => {
  const { values, setFieldValue } = useFormikContext()
  const prevTotalAmountRef = useRef();

  useEffect(() => {
    const totalAmount = values.bills.reduce((acc, bill) => {
      acc = !isNaN(parseInt(bill.amount)) && bill.id !== '' ? (acc = acc + parseInt(bill.amount)) : acc
      return acc
    }, 0)

    if (!isNaN(totalAmount) && prevTotalAmountRef.current !== totalAmount) {
      prevTotalAmountRef.current = totalAmount
      setFieldValue('totalAmount', totalAmount)
    }
  }, [values])

  return <AmountField disabled name='totalAmount' {...props} />
}

const BillingArray = ({values, ...props}) => {
  return (
    <FieldArray
       name="bills"
       render={arrayHelpers => (
         <Table borderless>
           <thead>
             <tr>
               <th className="ps-0">Référence facture</th>
               <th className="ps-1 text-end">Montant</th>
               <th></th>
             </tr>
           </thead>
           <tbody>
           {values.bills && values.bills.length > 0 && (
             values.bills.map((bill, index) => {
               const isRequired = bill.amount || bill.id
               return (
                 <tr key={index}>
                   <td className="p-0"><TextField name={`bills.${index}['id']`} {...props} required={isRequired} /></td>
                   <td className="p-0 ps-1"><AmountField name={`bills.${index}['amount']`} {...props} required={isRequired} /></td>
                   <td className="text-end">€ TTC</td>
                 </tr>
               )
             })
           )}
           <tr>
              <td className="p-0 align-middle"><div className="mb-3">TOTAL A PAYER</div></td>
              <td className="p-0 ps-1"><TotalAmount /></td>
              <td width="80" className="text-end">€ TTC</td>
            </tr>
           </tbody>
         </Table>
       )}
     />
  )
}

 
const BillingForm = ({ handleSubmit }) => {
  // const initialValues = { 
  //   firstName: 'Benoit',
  //   lastName: 'Woj',
  //   clientId: '123',
  //   company: 'Dudu & co',
  //   email: 'benoit.woj@gmail.com',
  //   phone: '03789637859',
  //   bills: [
  //     {id: '123', amount: '5000'}, 
  //     {id: '', amount: ''},
  //     {id: '', amount: ''},
  //     {id: '', amount: ''},
  //     {id: '', amount: ''}
  //   ], 
  //   totalAmount: '' 
  // }


  const initialValues = { 
    firstName: '',
    lastName: '',
    clientId: '',
    company: '',
    email: '',
    phone: '',
    bills: [
      {id: '', amount: ''}, 
      {id: '', amount: ''},
      {id: '', amount: ''},
      {id: '', amount: ''},
      {id: '', amount: ''}
    ], 
    totalAmount: '' 
  }



  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
      {({
          isSubmitting,
          handleSubmit,
          values
        }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col sm="12" md={{size: 6, offset: 3}}>
                  <fieldset className="mb-4">
                    <legend className="mb-2">Vos coordonnées</legend>
                    <TextField label="Votre référence client" type="text" name="clientId" required helpText="Retrouver votre numéro de client sur la facture."/>
                    <Row>
                      <Col>
                        <TextField label="Prénom" type="text" name="firstName" required />
                      </Col>
                      <Col>
                        <TextField label="Nom" type="text" name="lastName" required />
                      </Col>
                    </Row>
                    <TextField label="Société" type="text" name="company" required />
                    <TextField label="Email" type="text" name="email" required />
                    <TextField label="Téléphone" type="text" name="phone" required />
                  </fieldset>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md={{size: 6, offset: 3}}>
                  <fieldset>
                    <legend className="mb-2">Votre paiement</legend>
                    <BillingArray values={values} />
                  </fieldset>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm="12" md={{size: 6, offset: 3}} className="text-end">
                  <Button color="danger" size="lg" type="submit" disabled={isSubmitting}>
                   Procéder au paiement
                   </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Formik>
    </div>
  )
}
 
export default BillingForm