require('dotenv-flow').config()

import React, { useState, useEffect } from "react"

import { loadStripe } from "@stripe/stripe-js"
import { Elements, useStripe } from "@stripe/react-stripe-js"

import { initializeApp } from "firebase/app"
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions"
import { getAnalytics } from "firebase/analytics"

import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

import BillingForm from './BillingForm'
import CheckoutForm from "./CheckoutForm"

import 'bootstrap/dist/css/bootstrap.min.css'

import escdaLogo from './escda_logo.png'

const stripePromise = loadStripe(process.env.STRIPE_KEY)

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig)

const functions = getFunctions(app)
const analytics = getAnalytics(app)


if (process.env.LOCAL_EMULATOR === 'TRUE') {
	console.log('connectFunctionsEmulator')
	connectFunctionsEmulator(functions, "localhost", 5001)
}


const createPaymentIntent = httpsCallable(functions, 'createPaymentIntent')


const PaymentFormModal = ({ open, setOpen, totalAmount }) => {
	return (
		<div>
		  <Modal
		  	isOpen={open}
		    toggle={() => setOpen(false)}
		  >
		    <ModalHeader toggle={() => setOpen(false)}>
		      Paiement
		    </ModalHeader>
		    <ModalBody>
		      <p>Pour finaliser votre règlement merci d’indiquer vos coordonnées bancaires.</p>

		      <CheckoutForm totalAmount={totalAmount} />
		    </ModalBody>
		  </Modal>
		</div>
	)
}


export function App() {
	const [clientSecret, setClientSecret] = useState("")
	const [totalAmount, setTotalAmount] = useState("")
	const [open, setOpen] = useState(false)
	const [done, setDone] = useState(false)

	useEffect(() => {
		

		const redirect_status = new URLSearchParams(window.location.search).get(
	      "redirect_status"
	    )

	    if (redirect_status === "succeeded") {
	    	setDone(true)
	    }

	    
	}, [])


  	const initPaymentIntent =  (values) => {

	    createPaymentIntent(values)
  			.then((result) => {
    			// Read result of the Cloud Function.
    			const data = result.data
    			setClientSecret(data.clientSecret)
    			setOpen(true)
  		})
  	}

	const appearance = {
    	theme: 'stripe',
  	}
  	
  	const options = {
    	clientSecret,
    	appearance,
  	}


  	const handleSubmit = (values, { setSubmitting }) => {
  		setTotalAmount(values.totalAmount)
			initPaymentIntent(values)
			setSubmitting(false)
	}



  	return (
  		process.env.MAINTENANCE === 'TRUE' ? (
  			<Container>
  				<Row>
  					<Col sm="12">
						<div className="text-center py-5">
		  					<img className="d-block mx-auto mb-4" title="Loxam" alt="Loxam" src="//medias.loxam.com/medias/sys_master/images/images/hc6/loxam-logo/loxam-logo.svg" />
		  					<h2>Service en maintenance</h2>
		  					<p className="lead">
		  						Le service sera de nouveau disponible lundi.
		  					</p>
		  				</div>
		  			</Col>
  				</Row>
  			</Container>
  			) : (
  			<Container>
	  			<Row className="justify-content-md-center mb-4">
	  				{done ? (
	  					<Col sm="12">
		  					<div className="text-center py-5">
		  						<img className="d-block mx-auto mb-4" title="Loxam" alt="Loxam" src="//medias.loxam.com/medias/sys_master/images/images/hc6/loxam-logo/loxam-logo.svg" />
		  						<h2>Paiment effectué avec succès !</h2>
		  						<p className="lead">
		  							Le paiment a bien été pris en compte, vous allez recevoir un email de confimation.
		  						</p>
		  					</div>
		  				</Col>
	  				): (
	  					<Col sm="12">
		  					<div className="text-center py-5">
		  						<img className="mb-4 me-4" title="Loxam" alt="Loxam" src="//medias.loxam.com/medias/sys_master/images/images/hc6/loxam-logo/loxam-logo.svg" />
									<img className="mb-4" title="Loxam" alt="Loxam" height="65" src={escdaLogo} />
		  						<h2>Payer vos factures en ligne</h2>
		  						<p className="lead">
		  							Service réservé aux clients LOXAM en compte ayant reçu une facture
		  						</p>
		  					</div>
				  			<BillingForm handleSubmit={handleSubmit} />
		  				</Col>
	  				)}
	  			</Row>
	  			<footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
				    <div className="col-md-12 d-flex align-items-center">
				      <img className="d-block me-4" title="Loxam" alt="Loxam" src="//medias.loxam.com/medias/sys_master/images/images/hc6/loxam-logo/loxam-logo.svg" />
				      <span className="mb-3 mb-md-0 text-muted">© 2022 - Tous droits réservés - LOXAM 8, rue Félix Pyat 92043 Puteaux La Défense</span>
				    </div>

				    
				  </footer>
	  			 {clientSecret && (
		        		<Elements options={options} stripe={stripePromise}>
		          		<PaymentFormModal open={open} setOpen={setOpen} totalAmount={totalAmount} />
		        		</Elements>
		      		)}

	    	</Container>
  		)
  		
	)
}